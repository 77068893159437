<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss w-full">
    <div slot="no-body">
      <div class="justify-center items-center w-full">
        <div class="d-theme-dark-bg w-full" style="border-radius: 15px">
          <div class="p-5">
            <div class="logo">
              <img
                width="200"
                height="50"
                class="TextLogo"
                alt=""
                src="@/assets/images/logo.png"
              />
            </div>
            <div class="mt-4 text-center">
              <div
                class="OutlineElement Ltr BCX0 SCXW193994853"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                    sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
              
<div class=WordSection1>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;
direction:rtl;unicode-bidi:embed'><b><span lang=AR-SA style='font-size:22.0pt;
font-family:"Arial",sans-serif'>&#1605;&#1602;&#1583;&#1605;&#1577;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1605;&#1602;&#1589;&#1608;&#1583;
&#1576;&#1588;&#1585;&#1608;&#1591; &#1608;&#1571;&#1581;&#1603;&#1575;&#1605;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; </span><span
lang=AR-EG style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
</span><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577;
&#1601;&#1610; &#1607;&#1584;&#1607; &#1575;&#1604;&#1589;&#1601;&#1581;&#1577;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577; &#1607;&#1608;
&#1578;&#1606;&#1592;&#1610;&#1605; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1548;
&#1608;&#1603;&#1604; &#1589;&#1601;&#1581;&#1577; &#1605;&#1606;
&#1589;&#1601;&#1581;&#1575;&#1578;&#1607; (&#1575;&#1604;&#1605;&#1615;&#1589;&#1591;&#1604;&#1581;
&#1575;&#1604;&#1588;&#1575;&#1605;&#1604; &#1604;&#1580;&#1605;&#1610;&#1593;
&#1589;&#1601;&#1581;&#1575;&#1578; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1607;&#1608; </span><span lang=AR-EG style='font-size:14.0pt;font-family:
"Arial",sans-serif'>&quot;</span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&quot;). </span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1608;&#1575;&#1580;&#1576;&#1577; &#1575;&#1604;&#1606;&#1601;&#1575;&#1584;
&#1608;&#1578;&#1572;&#1579;&#1585; &#1593;&#1604;&#1609; &#1591;&#1585;&#1610;&#1602;&#1577;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;.
&#1576;&#1602;&#1576;&#1608;&#1604;&#1603;&#1605; &#1573;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span lang=AR-SA dir=LTR style='font-size:14.0pt;font-family:
"Arial",sans-serif'><span dir=LTR></span> </span><span lang=AR-EG
style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1601;&#1573;&#1606;&#1603;&#1605;
&#1576;&#1584;&#1604;&#1603;</span><span lang=AR-EG style='font-size:14.0pt;
font-family:"Arial",sans-serif'> </span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1578;&#1614;&#1602;&#1614;&#1576;&#1604;&#1608;&#1606;
&#1576;&#1588;&#1603;&#1604; &#1582;&#1575;&#1589; &#1580;&#1605;&#1610;&#1593;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605; &#1575;&#1604;&#1605;&#1615;&#1601;&#1614;&#1589;&#1614;&#1604;&#1577;
&#1608;&#1575;&#1604;&#1605;&#1576;&#1610;&#1606;&#1577; &#1607;&#1606;&#1575;.
&#1604;&#1575; &#1610;&#1580;&#1576;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1573;&#1584;&#1575;
&#1603;&#1606;&#1578; &#1605;&#1593;&#1578;&#1585;&#1590;&#1575;&#1614;
&#1593;&#1604;&#1609; &#1571;&#1610; &#1605;&#1606;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605; &#1575;&#1604;&#1605;&#1576;&#1610;&#1606;&#1577;
&#1607;&#1606;&#1575;. &#1604;&#1575; &#1610;&#1605;&#1603;&#1606; &#1604;&#1604;&#1602;&#1615;&#1589;&#1585;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593; (&#1608;&#1610;&#1593;&#1606;&#1610;
&#1601;&#1610; &#1607;&#1584;&#1607; &#1575;&#1604;&#1581;&#1575;&#1604;&#1577;
&#1575;&#1604;&#1571;&#1588;&#1582;&#1575;&#1589; &#1575;&#1604;&#1584;&#1610;&#1606;
&#1604;&#1605; &#1610;&#1576;&#1604;&#1594;&#1608;&#1575; &#1587;&#1606; 18
&#1593;&#1575;&#1605;&#1575;&#1611;). &#1608;&#1610;&#1615;&#1581;&#1592;&#1611;&#1585;
&#1593;&#1604;&#1609; &#1571;&#1610; &#1602;&#1575;&#1589;&#1616;&#1585; &#1575;&#1604;&#1583;&#1582;&#1608;&#1604;
&#1573;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span
dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><b><span lang=AR-SA style='font-size:14.0pt;font-family:
"Arial",sans-serif'>&#1605;&#1575; &#1607;&#1610;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605; &#1575;&#1604;</span></b><b><span
lang=AR-EG style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1605;&#1593;&#1610;&#1575;&#1585;&#1610;&#1577;
</span></b><b><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1604;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1567;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1578;&#1605;
&#1589;&#1610;&#1575;&#1594;&#1577; &#1588;&#1585;&#1608;&#1591;
&#1608;&#1571;&#1581;&#1603;&#1575;&#1605; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1601;&#1610; &#1588;&#1603;&#1604; &#1602;&#1575;&#1574;&#1605;&#1577;
&#1602;&#1608;&#1575;&#1593;&#1583; &#1578;&#1605;
&#1608;&#1590;&#1593;&#1607;&#1575; &#1576;&#1602;&#1589;&#1583; &#1573;&#1585;&#1587;&#1575;&#1569;
&#1575;&#1604;&#1571;&#1587;&#1575;&#1587; &#1575;&#1604;&#1584;&#1610;
&#1576;&#1605;&#1608;&#1580;&#1576;&#1607; &#1578;&#1578;&#1581;&#1583;&#1583;
&#1593;&#1604;&#1575;&#1602;&#1575;&#1578; &#1575;&#1604;&#1593;&#1605;&#1604;
&#1608;&#1576;&#1594;&#1585;&#1590; &#1578;&#1606;&#1592;&#1610;&#1605;
&#1575;&#1604;&#1573;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578; &#1575;&#1604;&#1605;&#1578;&#1603;&#1585;&#1585;&#1577;
&#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span
dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.5in;text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>1-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-SA style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1581;&#1602;&#1608;&#1602;
&#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
&#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1576;&#1575;&#1587;&#1578;&#1579;&#1606;&#1575;&#1569;
&#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609; &#1575;&#1604;&#1584;&#1610;
&#1575;&#1582;&#1578;&#1585;&#1578;&#1605;&#1608;&#1607; &#1591;&#1608;</span><span
lang=AR-EG style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1593;&#1610;&#1577;&#1611;
</span><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1604;&#1573;&#1583;&#1585;&#1575;&#1580;&#1607;
&#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &quot;<span
style='color:red'>&#1588;&#1585;&#1603;&#1577; </span></span><span lang=AR-EG
style='font-size:14.0pt;font-family:"Arial",sans-serif;color:red'>&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span><span lang=AR-EG
style='font-size:14.0pt;font-family:"Arial",sans-serif'>&quot;&#1548; &#1608;</span><span
lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1576;&#1605;&#1608;&#1580;&#1576;
&#1575;&#1604;&#1602;&#1608;&#1575;&#1593;&#1583;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
&#1593;&#1604;&#1610;&#1607;&#1575;&#1548; &#1601;&#1573;&#1606;
&#1575;&#1604;&#1605;&#1615;&#1585;&#1582;&#1616;&#1589; &#1607;&#1608;
&#1575;&#1604;&#1584;&#1610; &#1610;&#1578;&#1605;&#1578;&#1593; &#1576;&#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577;
&#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577;
&#1604;&#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1605;&#1608;&#1575;&#1583;
&#1608;&#1575;&#1604;&#1605;&#1605;&#1578;&#1604;&#1603;&#1575;&#1578; &#1575;&#1604;&#1601;&#1603;&#1585;&#1610;&#1577;
&#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577; &#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1608;&#1610;&#1581;&#1578;&#1601;&#1592;
&#1576;&#1603;&#1604; &#1575;&#1604;&#1581;&#1602;&#1608;&#1602;.</span><span
dir=LTR></span><span lang=AR-SA dir=LTR style='font-size:14.0pt;font-family:
"Arial",sans-serif'><span dir=LTR></span> </span><span lang=AR-SA
style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1578;&#1605;
&#1605;&#1606;&#1581; &#1580;&#1605;&#1610;&#1593;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
&#1578;&#1585;&#1582;&#1610;&#1589; &#1605;&#1581;&#1583;&#1608;&#1583;
&#1604;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609; &#1605;&#1585;&#1607;&#1608;&#1606;
&#1576;&#1575;&#1604;&#1602;&#1610;&#1608;&#1583;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1604;&#1604;&#1578;&#1605;&#1603;&#1606; &#1605;&#1606; &#1605;&#1588;&#1575;&#1607;&#1583;&#1577;
&#1605;&#1575; &#1610;&#1581;&#1578;&#1608;&#1610;&#1607;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;. </span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif;
color:red;background:#F2DBDB'>&#1606;&#1589;&#1610;&#1581;&#1577;
&#1573;&#1585;&#1588;&#1575;&#1583;&#1610;&#1577;</span><span dir=LTR></span><span
dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif;color:red;
background:#F2DBDB'><span dir=LTR></span>: </span><span lang=AR-SA
style='font-size:14.0pt;font-family:"Arial",sans-serif;color:red;background:
#F2DBDB'>&#1573;&#1584;&#1575; &#1603;&#1575;&#1606;
&#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609; &#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1602;&#1583;
&#1578;&#1605; &#1578;&#1589;&#1605;&#1610;&#1605;&#1607; &#1605;&#1606;
&#1571;&#1580;&#1604; &#1575;&#1604;&#1605;&#1588;&#1575;&#1607;&#1583;&#1577; &#1548;
&#1601;&#1610;&#1605;&#1603;&#1606; &#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;
&#1575;&#1604;&#1578;&#1602;&#1575;&#1591;&#1607; &#1605;&#1606; &#1575;&#1604;&#1606;&#1602;&#1591;&#1577;
&#1575;&#1604;&#1571;&#1582;&#1610;&#1585;&#1577; &#1576;&#1575;&#1604;&#1571;&#1593;&#1604;&#1609;.
&#1608;&#1605;&#1593; &#1584;&#1604;&#1603; &#1548; &#1610;&#1615;&#1581;&#1578;&#1614;&#1605;&#1614;&#1604;
&#1571;&#1606; &#1610;&#1615;&#1591;&#1604;&#1576; &#1605;&#1606;&#1603;&#1605;
&#1578;&#1602;&#1583;&#1610;&#1605; &#1575;&#1604;&#1605;&#1586;&#1610;&#1583; &#1576;&#1575;&#1604;&#1606;&#1587;&#1576;&#1577;
&#1604;&#1604;&#1589;&#1610;&#1594;&#1577; &#1575;&#1604;&#1608;&#1589;&#1601;&#1610;&#1577;
&#1576;&#1582;&#1589;&#1608;&#1589; &#1605;&#1575;
&#1610;&#1615;&#1587;&#1605;&#1581;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;
&#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607; &#1605;&#1606; &#1605;&#1608;&#1602;&#1593;&#1606;&#1575;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif;
color:black;background:#F2DBDB'><span dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.5in;text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>2-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-SA style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1602;&#1610;&#1608;&#1583;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1578;&#1605;
&#1578;&#1581;&#1583;&#1610;&#1583;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1576;&#1588;&#1603;&#1604; &#1602;&#1575;&#1591;&#1593;
&#1608;&#1582;&#1575;&#1589; &#1605;&#1605;&#1575; &#1610;&#1604;&#1610;:</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1606;&#1588;&#1585; &#1571;&#1610;
&#1605;&#1606; &#1575;&#1604;&#1605;&#1608;&#1575;&#1583;
&#1575;&#1604;&#1608;&#1575;&#1585;&#1583;&#1577; &#1601;&#1610;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1593;&#1604;&#1609; &#1571;&#1610; &#1605;&#1606;
&#1608;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1604;&#1573;&#1593;&#1604;&#1575;&#1605;.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1578;&#1587;&#1608;&#1610;&#1602;
&#1571;&#1608; &#1578;&#1585;&#1582;&#1610;&#1589; &#1605;&#1606;
&#1575;&#1604;&#1576;&#1575;&#1591;&#1606; &#1571;&#1608; &#1576;&#1610;&#1593;
&#1571;&#1610; &#1605;&#1608;&#1575;&#1583; &#1605;&#1606;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1576;&#1591;&#1585;&#1610;&#1602;&#1577; &#1602;&#1583;
&#1578;&#1572;&#1583;&#1610; &#1604;&#1604;&#1573;&#1590;&#1585;&#1575;&#1585;
&#1576;&#1607;.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1593;&#1585;&#1590; &#1608;/&#1571;&#1608; &#1573;&#1592;&#1607;&#1575;&#1585;
&#1604;&#1571;&#1610; &#1605;&#1606; &#1605;&#1608;&#1575;&#1583;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1571;&#1605;&#1575;&#1605; &#1575;&#1604;&#1593;&#1575;&#1605;&#1577;.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1576;&#1591;&#1585;&#1610;&#1602;&#1577; &#1578;&#1572;&#1579;&#1585;
&#1593;&#1604;&#1609; &#1583;&#1582;&#1608;&#1604;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
&#1575;&#1604;&#1570;&#1582;&#1585;&#1610;&#1606; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;</span><span lang=AR-EG
style='font-size:14.0pt;font-family:"Arial",sans-serif'>
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1576;&#1591;&#1585;&#1610;&#1602;&#1577;
&#1578;&#1578;&#1593;&#1575;&#1585;&#1590; &#1605;&#1593;
&#1575;&#1604;&#1604;&#1608;&#1575;&#1574;&#1581; &#1608;&#1575;&#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606;
&#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604; &#1576;&#1607;&#1575;
&#1571;&#1608; &#1576;&#1591;&#1585;&#1610;&#1602;&#1577; &#1602;&#1583;
&#1578;&#1590;&#1585; &#1571;&#1608; &#1578;&#1604;&#1581;&#1602;
&#1575;&#1604;&#1590;&#1585;&#1585;
&#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1571;&#1608; &#1571;&#1610; &#1588;&#1585;&#1603;&#1577; &#1571;&#1608;
&#1588;&#1582;&#1589;</span><span dir=LTR></span><span dir=LTR
style='font-size:14.0pt;font-family:"Arial",sans-serif'><span dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1576;&#1594;&#1585;&#1590; &#1575;&#1604;&#1573;&#1588;&#1578;&#1585;&#1575;&#1603;
&#1601;&#1610; &#1605;&#1580;&#1575;&#1604; &#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;
&#1571;&#1608; &#1575;&#1604;&#1573;&#1593;&#1604;&#1575;&#1606;</span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span
dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.75in;text-align:justify;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1575;&#1604;&#1602;&#1610;&#1575;&#1605;
&#1576;&#1571;&#1610; &#1593;&#1605;&#1604;&#1610;&#1575;&#1578; &#1604;&#1580;&#1605;&#1593;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1571;&#1608;
&#1575;&#1587;&#1578;&#1582;&#1585;&#1575;&#1580;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1571;&#1608;
&#1575;&#1587;&#1578;&#1582;&#1604;&#1575;&#1589; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1571;&#1608; &#1571;&#1609; &#1605;&#1606; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1571;&#1606;&#1588;&#1591;&#1577; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1571;&#1608; &#1571;&#1579;&#1606;&#1575;&#1569;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span
dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='margin-right:.5in;text-align:justify;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1578;&#1605; &#1601;&#1585;&#1590;
&#1602;&#1610;&#1608;&#1583;&#1575;&#1611; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606; &#1601;&#1610;
&#1573;&#1605;&#1603;&#1575;&#1606;&#1610;&#1577;
&#1583;&#1582;&#1608;&#1604;&#1607;&#1605; &#1593;&#1604;&#1609; &#1576;&#1593;&#1590;
&#1575;&#1604;&#1571;&#1580;&#1586;&#1575;&#1569;
&#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &quot;<span
style='color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span>&quot; &#1608;&#1602;&#1583;
&#1578;&#1615;&#1601;&#1585;&#1590; &#1602;&#1610;&#1608;&#1583;&#1575;&#1611;
&#1571;&#1582;&#1585;&#1609; &#1593;&#1604;&#1610;&#1607;&#1605; &#1576;&#1575;&#1604;&#1606;&#1587;&#1576;&#1577;
&#1604;&#1604;&#1583;&#1582;&#1608;&#1604; &#1573;&#1604;&#1609; &#1571;&#1580;&#1586;&#1575;&#1569;
&#1571;&#1582;&#1585;&#1609; &#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1608;&#1584;&#1604;&#1603;
&#1608;&#1601;&#1602;&#1575;&#1611; &#1604;&#1578;&#1602;&#1583;&#1610;&#1585;
&#1605;&#1575;&#1604;&#1603; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;.
&#1571;&#1610; &#1603;&#1604;&#1605;&#1577; &#1605;&#1585;&#1608;&#1585; &#1571;&#1608;&#1607;&#1608;&#1610;&#1577;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605; &#1578;&#1605;&#1603;&#1606;&#1607;
&#1605;&#1606; &#1575;&#1604;&#1583;&#1582;&#1608;&#1604; &#1573;&#1604;&#1609;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1578;&#1589;&#1576;&#1581; &#1587;&#1585;&#1610;&#1577; &#1608;&#1610;&#1578;&#1605;
&#1575;&#1604;&#1581;&#1601;&#1575;&#1592; &#1593;&#1604;&#1609;
&#1587;&#1585;&#1610;&#1577; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; .</span></p>

<span lang=AR-SA dir=RTL style='font-size:14.0pt;line-height:115%;font-family:
"Arial",sans-serif'><br clear=all style='page-break-before:always'>
</span>

<p class=MsoNormal><span lang=AR-SA dir=RTL style='font-size:14.0pt;line-height:
115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>3-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-SA style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1605;&#1581;&#1578;&#1614;&#1608;&#1614;&#1609;
&#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1603;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1608;&#1601;&#1602;&#1575;&#1611;
&#1604;&#1604;&#1588;&#1585;&#1608;&#1591; &#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1607;&#1584;&#1575;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1548; &#1573;&#1606;
</span><span lang=AR-EG style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;</span><span
lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1605;&#1581;&#1578;&#1614;&#1608;&#1614;&#1609;
&#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1575;&#1604;&#1605;&#1615;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;
&#1610;&#1593;&#1606;&#1610; &#1571;&#1610; &#1601;&#1610;&#1583;&#1610;&#1608;
&#1571;&#1608; &#1578;&#1587;&#1580;&#1610;&#1604; &#1589;&#1608;&#1578;&#1610;
&#1571;&#1608; &#1589;&#1608;&#1585; &#1571;&#1608; &#1606;&#1589;&#1608;&#1589;
&#1571;&#1608; &#1571;&#1610; &#1605;&#1608;&#1575;&#1583;
&#1571;&#1582;&#1585;&#1609; &#1610;&#1582;&#1578;&#1575;&#1585;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605; &#1593;&#1585;&#1590;&#1607;&#1575;
&#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;. &#1608;&#1601;&#1610;&#1605;&#1575;
&#1610;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1609;
&#1575;&#1604;&#1584;&#1610; &#1610;&#1593;&#1585;&#1590;&#1607;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;&#1548;
&#1610;&#1605;&#1606;&#1581; &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;
&#1604;&#1604;&#1605;&#1575;&#1604;&#1603; &#1578;&#1585;&#1582;&#1610;&#1589;&#1575;&#1611;
&#1593;&#1575;&#1604;&#1605;&#1610;&#1575;&#1611; &#1594;&#1610;&#1585;
&#1581;&#1589;&#1585;&#1610;&#1575;&#1611; &#1608;&#1582;&#1575;&#1604;&#1610;&#1575;&#1611;
&#1605;&#1606; &#1581;&#1602;&#1608;&#1602;
&#1575;&#1604;&#1605;&#1604;&#1603;&#1610;&#1577; &#1608;&#1594;&#1610;&#1585;
&#1602;&#1575;&#1576;&#1604; &#1604;&#1604;&#1573;&#1604;&#1594;&#1575;&#1569;
&#1608;&#1605;&#1585;&#1582;&#1589;&#1575;&#1611; &#1605;&#1606;
&#1575;&#1604;&#1576;&#1575;&#1591;&#1606;
&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1605;&#1581;&#1578;&#1614;&#1608;&#1614;&#1609;
&#1608;&#1606;&#1588;&#1585;&#1607; &#1608;&#1605;&#1608;&#1575;&#1569;&#1605;&#1578;&#1607;
&#1608;&#1578;&#1585;&#1580;&#1605;&#1578;&#1607;
&#1608;&#1575;&#1587;&#1578;&#1606;&#1587;&#1575;&#1582;&#1607;
&#1608;&#1578;&#1608;&#1586;&#1610;&#1593;&#1607; &#1593;&#1604;&#1609;
&#1571;&#1609; &#1605;&#1606; &#1608;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1604;&#1573;&#1593;&#1604;&#1575;&#1605;</span><span dir=LTR></span><span
dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span dir=LTR></span>.
</span><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1610;&#1580;&#1576;
&#1571;&#1606; &#1610;&#1603;&#1608;&#1606;
&#1575;&#1604;&#1605;&#1581;&#1578;&#1608;&#1614;&#1609;
&#1575;&#1604;&#1584;&#1610; &#1610;&#1593;&#1585;&#1590;&#1607;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;&#1608;&#1606; &#1605;&#1581;&#1578;&#1608;&#1609;
&#1582;&#1575;&#1589; &#1576;&#1607;&#1605; &#1608;&#1610;&#1580;&#1576; &#1571;&#1604;&#1575;
&#1610;&#1606;&#1578;&#1607;&#1603; &#1571;&#1576;&#1583;&#1575;&#1611;
&#1571;&#1609; &#1581;&#1602; &#1605;&#1606; &#1581;&#1602;&#1608;&#1602;
&#1571;&#1610; &#1591;&#1585;&#1601; &#1579;&#1575;&#1604;&#1579;.
&#1578;&#1605;&#1578;&#1604;&#1603; &quot;<span style='color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span>&quot;</span><span dir=LTR></span><span
lang=AR-SA dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span
dir=LTR></span> </span><span lang=AR-SA style='font-size:14.0pt;font-family:
"Arial",sans-serif'>&#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
&#1581;&#1584;&#1601; &#1571;&#1610; &#1605;&#1581;&#1578;&#1608;&#1609;
&#1605;&#1606; &#1605;&#1581;&#1578;&#1608;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605; &#1605;&#1606;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1581;&#1587;&#1576;
&#1578;&#1602;&#1583;&#1610;&#1585;&#1607;&#1575; &#1583;&#1608;&#1606; &#1573;&#1582;&#1591;&#1575;&#1585;
&#1605;&#1587;&#1576;&#1602; &#1608;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
&#1582;&#1575;&#1589;&#1577; &#1576;&#1607;&#1575;</span><span dir=LTR></span><span
dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>4-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-EG style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1606;&#1593;&#1583;&#1575;&#1605;
&#1575;&#1604;</span></b><b><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1590;&#1605;&#1575;&#1606;&#1575;&#1578;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1578;&#1605;
&#1578;&#1602;&#1583;&#1610;&#1605; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1601;&#1610;
&#1588;&#1603;&#1604;&#1607; &#1575;&#1604;&#1585;&#1575;&#1607;&#1606;
&#1588;&#1575;&#1605;&#1604; &#1575;&#1604;&#1593;&#1610;&#1608;&#1576;&#1548; &#1608;&#1604;&#1575;
&#1610;&#1608;&#1580;&#1583; &#1590;&#1605;&#1575;&#1606;
&#1590;&#1605;&#1606;&#1610; &#1571;&#1608; &#1589;&#1585;&#1610;&#1581;
&#1605;&#1606; &quot;<span style='color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span>&quot; &#1576;&#1571;&#1610;
&#1588;&#1603;&#1604; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1588;&#1603;&#1575;&#1604; &#1601;&#1610;&#1605;&#1575;
&#1610;&#1582;&#1589; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1571;&#1608; &#1575;&#1604;&#1605;&#1608;&#1575;&#1583; &#1575;&#1604;&#1578;&#1610;
&#1578;&#1581;&#1578;&#1608;&#1610;</span><span lang=AR-EG style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1607;</span><span lang=AR-SA
style='font-size:14.0pt;font-family:"Arial",sans-serif'>. &#1608;&#1573;&#1590;&#1575;&#1601;&#1577;&#1611;
&#1573;&#1604;&#1609; &#1584;&#1604;&#1603;&#1548; &#1610;&#1580;&#1576; &#1571;&#1604;&#1575;
&#1578;&#1572;&#1582;&#1584; &#1571;&#1610;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1605;&#1608;&#1580;&#1608;&#1583;&#1577;
&#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1576;&#1605;&#1593;&#1606;&#1609; &#1571;&#1606;&#1607; &#1578;&#1605; &#1578;&#1602;&#1583;&#1610;&#1605;
&#1605;&#1588;&#1608;&#1585;&#1577; &#1571;&#1608; &#1582;&#1583;&#1605;&#1577;
&#1575;&#1587;&#1578;&#1588;&#1575;&#1585;&#1610;&#1577; &#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;</span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span
dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><b><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>5-&#1578;&#1581;&#1583;&#1610;&#1583;
&#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1604;&#1575;
&#1610;&#1580;&#1608;&#1586; &#1578;&#1581;&#1578; &#1571;&#1610;
&#1592;&#1585;&#1601; &#1605;&#1606; &#1575;&#1604;&#1592;&#1585;&#1608;&#1601;
&#1575;&#1593;&#1578;&#1576;&#1575;&#1585;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&quot;<span style='color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span>&quot; &#1571;&#1608;
&#1571;&#1610; &#1605;&#1606;
&#1575;&#1604;&#1605;&#1608;&#1592;&#1601;&#1610;&#1606; &#1608;&#1575;&#1604;&#1605;&#1587;&#1574;&#1608;&#1604;&#1610;&#1606;
&#1608;&#1575;&#1604;&#1605;&#1583;&#1610;&#1585;&#1610;&#1606; &#1605;&#1587;&#1574;&#1608;&#1604;&#1610;&#1606;
&#1576;&#1571;&#1610; &#1588;&#1603;&#1604; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1588;&#1603;&#1575;&#1604; &#1571;&#1605;&#1575;&#1605;
&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1593;&#1606; &#1571;&#1610; &#1605;&#1608;&#1575;&#1602;&#1601;
&#1606;&#1575;&#1580;&#1605;&#1577; &#1593;&#1606;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1571;&#1608; &#1571;&#1609;
&#1593;&#1602;&#1608;&#1583; &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1605;&#1571;&#1582;&#1608;&#1584;
&#1605;&#1606;&#1607;&#1575; &#1593;&#1610;&#1606;&#1575;&#1578;
&#1576;&#1571;&#1609; &#1588;&#1603;&#1604; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1588;&#1603;&#1575;&#1604;&#1548;
&#1587;&#1608;&#1575;&#1569; &#1603;&#1575;&#1606;&#1578; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1587;&#1574;&#1608;&#1604;&#1610;&#1577;
&#1578;&#1606;&#1583;&#1585;&#1580; &#1578;&#1581;&#1578;
&#1573;&#1591;&#1575;&#1585; &#1590;&#1585;&#1585; &#1571;&#1608;
&#1593;&#1602;&#1583; &#1571;&#1608; &#1571;&#1610;
&#1581;&#1575;&#1604;&#1577; &#1571;&#1582;&#1585;&#1609;. &#1604;&#1606;
&#1578;&#1582;&#1590;&#1593; &quot;<span style='color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span>&quot;
&#1608;&#1603;&#1584;&#1604;&#1603; &#1575;&#1604;&#1605;&#1587;&#1574;&#1608;&#1604;&#1608;&#1606;
&#1608;&#1575;&#1604;&#1605;&#1608;&#1592;&#1601;&#1608;&#1606;
&#1608;&#1575;&#1604;&#1605;&#1583;&#1610;&#1585;&#1608;&#1606; &#1604;&#1604;&#1605;&#1587;&#1575;&#1569;&#1604;&#1577;
&#1593;&#1606; &#1605;&#1587;&#1574;&#1608;&#1604;&#1610;&#1575;&#1578;&#1607;&#1605;
&#1594;&#1610;&#1585; &#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1577;
&#1571;&#1608; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1571;&#1608; &#1575;&#1604;&#1605;&#1578;&#1585;&#1578;&#1576;&#1577;
&#1593;&#1604;&#1609; &#1571;&#1610; &#1588;&#1610;&#1569;
&#1605;&#1585;&#1578;&#1576;&#1591;
&#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
20 px;font-family:"Arial",sans-serif'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><b><span lang=AR-SA style='font-size:20px;
font-family:"Arial",sans-serif'>6-&#1575;&#1604;&#1578;&#1593;&#1608;&#1610;</span></b><b><span
lang=AR-EG style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1590;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1610;&#1602;&#1608;&#1605;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605; &#1576;&#1575;&#1604;&#1578;&#1571;&#1605;&#1610;&#1606;
&quot;<span style='color:red'>&#1604;&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span>&quot; &#1578;&#1571;&#1605;&#1610;&#1606;&#1575;&#1611;
&#1603;&#1575;&#1605;&#1604;&#1575;&#1611; &#1590;&#1583; &#1571;&#1610;
&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578; &#1571;&#1608;
&#1571;&#1587;&#1576;&#1575;&#1576; &#1571;&#1608;
&#1605;&#1591;&#1575;&#1604;&#1576; &#1571;&#1608;
&#1571;&#1590;&#1585;&#1575;&#1585; &#1571;&#1608;
&#1606;&#1601;&#1602;&#1575;&#1578; &#1606;&#1575;&#1580;&#1605;&#1577;
&#1593;&#1606; &#1571;&#1608; &#1578;&#1578;&#1593;&#1604;&#1602; &#1576;&#1571;&#1610;
&#1575;&#1606;&#1578;&#1607;&#1575;&#1603;
&#1604;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
&#1593;&#1604;&#1610;&#1607;&#1575; (&#1576;&#1605;&#1575; &#1601;&#1610;
&#1584;&#1604;&#1603; &#1571;&#1578;&#1593;&#1575;&#1576;
&#1575;&#1604;&#1605;&#1581;&#1575;&#1605;&#1575;&#1577; &#1575;&#1604;&#1578;&#1610;
&#1578;&#1593;&#1578;&#1576;&#1585; &#1605;&#1606;&#1591;&#1602;&#1610;&#1577;).</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;margin-top:5px;font-size:20px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>7-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-SA style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1602;&#1575;&#1576;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1601;&#1589;&#1604;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1601;&#1610;
&#1581;&#1575;&#1604;&#1577; &#1576;&#1591;&#1604;&#1575;&#1606; &#1571;&#1610;
&#1576;&#1606;&#1583; &#1605;&#1606; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1571;&#1608; &#1593;&#1583;&#1605;
&#1602;&#1575;&#1576;&#1604;&#1610;&#1578;&#1607;
&#1604;&#1604;&#1606;&#1601;&#1575;&#1584; &#1576;&#1605;&#1608;&#1580;&#1576;
&#1571;&#1610; &#1605;&#1606;
&#1575;&#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606; &#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604;
&#1576;&#1607;&#1575;&#1548; &#1601;&#1573;&#1606; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1576;&#1591;&#1604;&#1575;&#1606; &#1571;&#1608;
&#1593;&#1583;&#1605; &#1575;&#1604;&#1606;&#1601;&#1575;&#1584; &#1604;&#1606;
&#1610;&#1578;&#1587;&#1576;&#1576;&#1575; &#1601;&#1610; &#1593;&#1583;&#1605;
&#1589;&#1604;&#1575;&#1581;&#1610;&#1577; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1571;&#1608; &#1593;&#1583;&#1605;
&#1602;&#1575;&#1576;&#1604;&#1610;&#1578;&#1607;&#1575; &#1604;&#1604;&#1606;&#1601;&#1575;&#1584;
&#1576;&#1585;&#1615;&#1605;&#1614;&#1578;&#1616;&#1607;&#1575;.
&#1576;&#1575;&#1604;&#1573;&#1590;&#1575;&#1601;&#1577; &#1573;&#1604;&#1609;
&#1584;&#1604;&#1603;&#1548; &#1610;&#1615;&#1604;&#1594;&#1609;
&#1575;&#1604;&#1581;&#1615;&#1603;&#1605; &#1594;&#1610;&#1585;
&#1575;&#1604;&#1589;&#1575;&#1604;&#1581; &#1608;&#1594;&#1610;&#1585;
&#1575;&#1604;&#1602;&#1575;&#1576;&#1604; &#1604;&#1604;&#1606;&#1601;&#1575;&#1584;
&#1583;&#1608;&#1606; &#1575;&#1604;&#1578;&#1571;&#1579;&#1610;&#1585;
&#1576;&#1571;&#1610; &#1588;&#1603;&#1604; &#1605;&#1606; &#1575;&#1604;&#1571;&#1588;&#1603;&#1575;&#1604;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;
&#1575;&#1604;&#1571;&#1582;&#1585;&#1609;.</span></p>

<span lang=AR-SA dir=RTL style='font-size:14.0pt;line-height:115%;font-family:
"Arial",sans-serif'><br clear=all style='page-break-before:always'>
</span>

<p class=MsoNormal><span lang=AR-SA dir=RTL style='font-size:14.0pt;line-height:
115%;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-family:"Arial",sans-serif'>8-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-SA style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1578;&#1593;&#1583;&#1610;&#1604;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1605;&#1587;&#1605;&#1608;&#1581;
&#1604;&#1571;&#1589;&#1581;&#1575;&#1576;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &quot;<span
style='color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577;</span>&quot; &#1576;&#1573;&#1580;&#1585;&#1575;&#1569;
&#1605;&#1585;&#1575;&#1580;&#1593;&#1575;&#1578;
&#1604;&#1604;&#1588;&#1585;&#1608;&#1591; </span><span lang=AR-SA
style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1581;&#1587;&#1576;
&#1585;&#1594;&#1576;&#1578;&#1607;&#1605; &#1608;&#1581;&#1587;&#1576;
&#1605;&#1575; &#1610;&#1585;&#1608;&#1606;&#1607; &#1605;&#1606;&#1575;&#1587;&#1576;&#1575;&#1611;</span><span
lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>. &#1608;</span><span
lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1576;&#1602;&#1576;&#1608;&#1604;&#1603;&#1605;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1548; &#1601;&#1605;&#1606;
&#1575;&#1604;&#1605;&#1578;&#1608;&#1602;&#1593; &#1571;&#1606;
&#1578;&#1602;&#1608;&#1605;&#1608;&#1575;
&#1576;&#1602;&#1585;&#1575;&#1569;&#1577; </span><span lang=AR-SA
style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1576;&#1575;&#1606;&#1578;&#1592;&#1575;&#1605;
&#1604;&#1604;&#1578;&#1571;&#1603;&#1583; &#1605;&#1606; &#1601;&#1607;&#1605;
&#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1608;&#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605; &#1575;&#1604;&#1578;&#1610;
&#1578;&#1608;&#1580;&#1607; &#1593;&#1605;&#1604;&#1610;&#1577; &#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1604;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span
dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-EG style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>9-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-SA style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1575;&#1604;&#1578;&#1606;&#1575;&#1586;&#1604;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1604;&#1583;&#1609;
&#1605;&#1608;&#1602;&#1593;&quot;</span><span dir=LTR></span><span lang=AR-SA
dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span dir=LTR></span>
</span><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif;
color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577; &#1575;&#1604;&#1591;&#1576;&#1610;&#1577;&quot;</span><span
dir=LTR></span><span lang=AR-SA dir=LTR style='font-size:14.0pt;font-family:
"Arial",sans-serif'><span dir=LTR></span> </span><span lang=AR-SA
style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1589;&#1604;&#1575;&#1581;&#1610;&#1577;
&#1575;&#1604;&#1606;&#1602;&#1604;
&#1608;&#1575;&#1604;&#1578;&#1593;&#1575;&#1602;&#1583; &#1605;&#1606;
&#1575;&#1604;&#1576;&#1575;&#1591;&#1606;
&#1608;&#1575;&#1604;&#1578;&#1606;&#1575;&#1586;&#1604; &#1593;&#1606; &#1575;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1581;&#1602;&#1608;&#1602; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
&#1576;&#1607; &#1576;&#1605;&#1608;&#1580;&#1576;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1605;&#1606;&#1589;&#1608;&#1589;
&#1593;&#1604;&#1610;&#1607;&#1575; &#1583;&#1608;&#1606;
&#1575;&#1604;&#1581;&#1575;&#1580;&#1577; &#1575;&#1604;&#1609; &#1575;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1593;&#1604;&#1609; &#1605;&#1608;&#1575;&#1601;&#1602;&#1577; &#1571;&#1608;
&#1573;&#1589;&#1583;&#1575;&#1585; &#1573;&#1582;&#1591;&#1575;&#1585;. &#1594;&#1610;&#1585;&#1571;&#1606;&#1607;
&#1594;&#1610;&#1585; &#1605;&#1587;&#1605;&#1608;&#1581; &#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1576;&#1606;&#1602;&#1604;
&#1571;&#1608; &#1575;&#1604;&#1578;&#1593;&#1575;&#1602;&#1583; &#1605;&#1606;
&#1575;&#1604;&#1576;&#1575;&#1591;&#1606; &#1571;&#1608;
&#1575;&#1604;&#1578;&#1606;&#1575;&#1586;&#1604; &#1593;&#1606; &#1571;&#1610;
&#1605;&#1606;
&#1575;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578; &#1608;</span><span
lang=AR-EG style='font-size:14.0pt;font-family:"Arial",sans-serif'>/</span><span
lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1571;&#1608;
&#1575;&#1604;&#1581;&#1602;&#1608;&#1602; &#1576;&#1605;&#1608;&#1580;&#1576;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591;</span><span dir=LTR></span><span
dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'><span dir=LTR></span>.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><b><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>10-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><span dir=RTL></span><b><span lang=AR-SA style='font-size:
14.0pt;font-family:"Arial",sans-serif'>&#1605;&#1615;&#1580;&#1605;&#1614;&#1604;
&#1575;&#1604;&#1575;&#1578;&#1601;&#1575;&#1602;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1578;&#1588;&#1603;&#1604;
&#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1548;
&#1605;&#1593; &#1573;&#1583;&#1585;&#1575;&#1580;
&#1575;&#1606;&#1578;&#1601;&#1575;&#1569; &#1575;&#1604;&#1605;&#1587;&#1574;&#1608;&#1604;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1573;&#1588;&#1593;&#1575;&#1585;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1548; &#1593;&#1602;&#1583;&#1575;&#1611;
&#1587;&#1604;&#1610;&#1605;&#1575;&#1611; &#1576;&#1610;&#1606;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1616;&#1605; &#1608;<span
style='color:red'>&#1588;&#1585;&#1603;&#1577;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1581;&#1577;
&#1575;&#1604;&#1591;&#1576;&#1610;&#1577; </span>&#1601;&#1610;&#1605;&#1575;
&#1610;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;. </span><span
lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1608;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1588;&#1585;&#1608;&#1591; &#1578;&#1601;&#1608;&#1602;
&#1571;&#1610; &#1578;&#1601;&#1575;&#1607;&#1605;&#1575;&#1578;
&#1608;&#1575;&#1578;&#1601;&#1575;&#1602;&#1610;&#1575;&#1578;
&#1587;&#1575;&#1576;&#1602;&#1577; &#1601;&#1610;&#1605;&#1575;
&#1610;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1575;&#1604;&#1605;&#1608;&#1602;&#1593; &#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;font-size:20px;margin-top:5px;
text-indent:-.25in;direction:rtl;unicode-bidi:embed'><span style='font-size:
14.0pt;font-family:"Arial",sans-serif'>11-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><b><span lang=AR-SA style='font-size:14.0pt;
font-family:"Arial",sans-serif'>&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
&#1575;&#1604;&#1581;&#1575;&#1603;&#1605;
&#1608;&#1575;&#1604;&#1575;&#1582;&#1578;&#1589;&#1575;&#1589;
&#1575;&#1604;&#1602;&#1590;&#1575;&#1574;&#1610;</span></b></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1578;&#1615;&#1601;&#1587;&#1585;
&#1608;&#1578;&#1614;&#1582;&#1590;&#1614;&#1593; &#1588;&#1585;&#1608;&#1591;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1608;&#1601;&#1602;&#1575;&#1611;
&#1604;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606; &#1583;&#1608;&#1604;&#1577; &#1575;&#1604;&#1573;&#1605;&#1575;&#1585;&#1575;&#1578;
&#1575;&#1604;&#1593;&#1585;&#1576;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1581;&#1583;&#1577;. &#1610;&#1580;&#1576;
&#1593;&#1604;&#1609; &#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610; &#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1575;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1571;&#1606; &#1610;&#1602;&#1576;&#1604;&#1608;&#1575; &#1575;&#1604;&#1605;&#1579;&#1608;&#1604;
&#1571;&#1605;&#1575;&#1605; &#1605;&#1581;&#1575;&#1603;&#1605;
&#1583;&#1576;&#1610; &#1608;&#1575;&#1604;&#1575;&#1582;&#1578;&#1589;&#1575;&#1589;
&#1594;&#1610;&#1585; &#1575;&#1604;&#1581;&#1589;&#1585;&#1610; &#1604;&#1583;&#1608;&#1604;&#1577;
&#1575;&#1604;&#1573;&#1605;&#1575;&#1585;&#1575;&#1578;
&#1575;&#1604;&#1593;&#1585;&#1576;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1581;&#1583;&#1577;
&#1604;&#1578;&#1587;&#1608;&#1610;&#1577; &#1575;&#1604;&#1605;&#1606;&#1575;&#1586;&#1593;&#1575;&#1578;.
</span><span lang=AR-SA style='font-size:14.0pt;font-family:"Arial",sans-serif'>&#1604;&#1575;
&#1610;&#1588;&#1603;&#1604; &#1575;&#1604;&#1605;&#1582;&#1591;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605; &#1571;&#1610;
&#1578;&#1585;&#1578;&#1610;&#1576;&#1575;&#1578; &#1578;&#1608;&#1603;&#1610;&#1604;&#1610;&#1577;
&#1576;&#1610;&#1606; &#1571;&#1610; &#1603;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1571;&#1608; &#1571;&#1588;&#1582;&#1575;&#1589;.
&#1593;&#1604;&#1575;&#1608;&#1577; &#1593;&#1604;&#1609; &#1584;&#1604;&#1603;
&#1548; &#1601;&#1573;&#1606; &#1575;&#1604;&#1605;&#1582;&#1591;&#1591; &#1575;&#1604;&#1593;&#1575;&#1605;
&#1605;&#1578;&#1575;&#1581; &#1604;&#1580;&#1605;&#1610;&#1593;
&#1575;&#1604;&#1571;&#1593;&#1590;&#1575;&#1569;
&#1576;&#1605;&#1608;&#1580;&#1576; &#1575;&#1578;&#1601;&#1575;&#1602; &#1576;&#1571;&#1606;
<span style='color:red'>&#1575;&#1604;&#1588;&#1585;&#1603;&#1577;</span>
&#1594;&#1610;&#1585; &#1605;&#1593;&#1606;&#1610;&#1577;
&#1576;&#1578;&#1602;&#1583;&#1610;&#1605; &#1571;&#1610;
&#1605;&#1588;&#1608;&#1585;&#1577; &#1605;&#1607;&#1606;&#1610;&#1577;
&#1571;&#1608; &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;.
&#1610;&#1605;&#1603;&#1606; &#1578;&#1594;&#1610;&#1610;&#1585;
&#1575;&#1604;&#1605;&#1582;&#1591;&#1591; &#1575;&#1604;&#1593;&#1575;&#1605; &#1583;&#1608;&#1606;
&#1573;&#1588;&#1593;&#1575;&#1585; &#1605;&#1587;&#1576;&#1602;
&#1604;&#1604;&#1571;&#1593;&#1590;&#1575;&#1569;. &#1604;&#1606;
&#1578;&#1603;&#1608;&#1606; <span style='color:red'>&#1575;&#1604;&#1588;&#1585;&#1603;&#1577;
</span>&#1605;&#1587;&#1574;&#1608;&#1604;&#1577; &#1593;&#1606;
&#1575;&#1604;&#1573;&#1594;&#1601;&#1575;&#1604;&#1575;&#1578; &#1571;&#1608;
&#1575;&#1604;&#1571;&#1582;&#1591;&#1575;&#1569; &#1571;&#1608;
&#1575;&#1604;&#1591;&#1585;&#1610;&#1602;&#1577; &#1575;&#1604;&#1578;&#1610;
&#1610;&#1578;&#1605; &#1576;&#1607;&#1575; &#1578;&#1601;&#1587;&#1610;&#1585;
&#1571;&#1608; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1575;&#1604;&#1605;&#1582;&#1591;&#1591;
&#1575;&#1604;&#1593;&#1575;&#1605; &#1571;&#1608; &#1593;&#1606;
&#1571;&#1610; &#1606;&#1578;&#1575;&#1574;&#1580; &#1606;&#1575;&#1580;&#1605;&#1577;
&#1593;&#1606; &#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1594;&#1610;&#1585; &#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;
&#1571;&#1608; &#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;
&#1604;&#1604;&#1605;&#1582;&#1591;&#1591; &#1575;&#1604;&#1593;&#1575;&#1605;.
&#1610;&#1615;&#1606;&#1589;&#1581;
&#1575;&#1604;&#1571;&#1593;&#1590;&#1575;&#1569;
&#1576;&#1575;&#1587;&#1578;&#1588;&#1575;&#1585;&#1577; &#1575;&#1604;&#1582;&#1576;&#1585;&#1575;&#1569;
&#1571;&#1608; &#1575;&#1604;&#1605;&#1581;&#1575;&#1605;&#1610;&#1606;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1610;&#1606; &#1575;&#1604;&#1605;&#1606;&#1575;&#1587;&#1576;&#1610;&#1606;
&#1604;&#1604;&#1581;&#1589;&#1608;&#1604; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1588;&#1608;&#1585;&#1577;
&#1575;&#1604;&#1605;&#1607;&#1606;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;.</span></p>

<p class=MsoNoSpacing dir=RTL style='text-align:justify;direction:rtl;
unicode-bidi:embed'><span dir=LTR style='font-size:14.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

</div>

              </div>
            
            </div>

            <div style="text-align: center">
              <vs-button
                style="width: 60%; margin-top: 2rem"
                @click="$router.go(-1)"
                >{{ $t("back") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


